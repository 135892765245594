import React from "react"
import { useIntl } from "react-intl"
import { Home } from "../components/Home/Home"
import { HomeSearch } from "../components/HomeSearch/HomeSearch"
import { Layout } from "../components/Layout/Layout"
import { Seo } from "../components/Seo/Seo"

const HomeTemplate = props => {
  const intl = useIntl()
  return (
    <>
      <Seo
        title={intl.formatMessage({ id: "home/seo/title" })}
        description={intl.formatMessage({ id: "home/seo/description" })}
        indexable={true}
        domain={props.pageContext.configuration.domain}
        lang={props.pageContext.language}
        langUrls={props.pageContext.canonicals}
      />

      <HomeSearch logo={props.pageContext.header.logo} />
    </>
  )
}

export default HomeTemplate
